import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Strong, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0",
	"sm-padding": "40px 0"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "20px 0 20px 0",
			"text-align": "center",
			"children": <>
				Обираючи{" "}
				<Strong>
					EcoDrive Service Center
				</Strong>
				, ви отримуєте найкращий сервіс для вашого автомобіля. Ми завжди готові бути вашими надійними партнерами на дорозі до екологічної та економічної автоподорожі, забезпечивши вам не лише якісний сервіс для автомобіля, але і максимальний комфорт, знання і зручність під час візиту до нашого сервісного центру.{"\n\n"}
			</>
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-radius": "8px",
			"href": "/contact-us",
			"text-decoration-line": "initial",
			"sm-padding": "5px 10px 5px 10px",
			"md-padding": "5px 10px 5px 10px",
			"sm-margin": "10px 0px 10px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "--light",
			"md-margin": "10px 0px 0px 0px",
			"border-width": 0,
			"padding": "8px 18px 9px 18px",
			"background": "--color-primary",
			"children": "Зв'язатися з Нами"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" align-items="center" />
		<Text {...override("text")} />
		<Link {...override("link")} />
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;