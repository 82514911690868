import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Strong, Box, Icon, Section } from "@quarkly/widgets";
import { MdDone } from "react-icons/md";
const defaultProps = {
	"padding": "90px 0 90px 0",
	"sm-padding": "40px 0",
	"sm-min-height": "auto",
	"display": "flex",
	"quarkly-title": "Advantages/Features-8"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "35%",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-align-items": "flex-start",
			"lg-margin": "0px 0px 60px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap",
			"sm-width": "100%",
			"md-margin": "0px 0px 30px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 8px 0px",
			"font": "--lead",
			"color": "--primary",
			"width": "100%",
			"md-width": "100%",
			"md-margin": "0px 0px 16px 0px",
			"md-padding": "0px 0px 0px 0px",
			"text-align": "left",
			"md-text-align": "center",
			"sm-text-align": "left",
			"children": <Strong>
				Чому слід обрати саме нас
			</Strong>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 24px 0px",
			"font": "--headline2",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"md-margin": "0px 0px 32px 0px",
			"md-padding": "0px 0px 0px 0px",
			"sm-font": "normal 600 40px/1.2 --fontFamily-sans",
			"text-align": "left",
			"md-text-align": "center",
			"sm-text-align": "left",
			"children": <>
				Наші переваги{"\n\n"}
			</>
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"md-margin": "0px 0px 32px 0px",
			"md-padding": "0px 0px 0px 0px",
			"text-align": "left",
			"md-text-align": "center",
			"sm-text-align": "left",
			"children": <>
				Обираючи "EcoDrive Service Center" для обслуговування вашого автомобіля і установки системи ГБО, ви отримуєте безліч переваг:{"\n\n"}
			</>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "60%",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "48px 32px",
			"lg-grid-gap": "48px",
			"lg-width": "100%",
			"sm-grid-template-columns": "1fr",
			"md-grid-gap": "36px "
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--primary",
			"margin": "2px 16px 0px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"children": <>
				<Strong>
					Безкоштовний виклик таксі для клієнта
				</Strong>
				{"\n\n"}
			</>
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "12px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"lg-margin": "8px 0px 0px 0px",
			"children": <>
				Усвідомлюючи, що час наших клієнтів є важливим ресурсом, ми пропонуємо безкоштовний виклик таксі для вас. Це дозволить вам зручно дістатися з центру у любу точку міста, зекономивши час і зусилля.{"\n\n"}
			</>
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--primary",
			"margin": "2px 16px 0px 0px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"children": <Strong>
				{"\n"}Екологічна освіта і підтримка{"\n\n"}
			</Strong>
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "12px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"lg-margin": "8px 0px 0px 0px",
			"children": <>
				Ми допомагаємо вам розуміти, як зробити ваш автомобіль більш екологічним і зменшити його вплив на навколишнє середовище. Ми надаємо консультації та навчаємо користувачів зеленим технологіям і стилю водіння.{"\n\n"}
			</>
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--primary",
			"margin": "2px 16px 0px 0px"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"children": <Strong>
				{"\n"}Зменшення витрат на пальне{"\n\n"}
			</Strong>
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "12px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"lg-margin": "8px 0px 0px 0px",
			"children": <>
				Установка системи ГБО дозволяє значно зменшити витрати на пальне, що призводить до економії коштів на заправках. Ви отримуєте можливість ефективніше використовувати свій автомобіль.{"\n\n"}
			</>
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--primary",
			"margin": "2px 16px 0px 0px"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				{"\n\n"}Професіоналізм і досвід{"\n\n"}
			</Strong>
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "12px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"lg-margin": "8px 0px 0px 0px",
			"children": <>
				Наша команда фахівців має багаторічний досвід у встановленні і обслуговуванні систем ГБО. Ми гарантуємо якість і надійність усіх наших послуг.{"\n\n"}
			</>
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row"
		}
	},
	"icon4": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--primary",
			"margin": "2px 16px 0px 0px"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"children": <Strong>
				{"\n"}Комплексність обслуговування{"\n\n"}
			</Strong>
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "12px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"lg-margin": "8px 0px 0px 0px",
			"children": <>
				Ми пропонуємо не лише установку ГБО, але й регулярне обслуговування, ремонт, модернізацію та інші послуги для підтримки вашого автомобіля в ідеальному стані.{"\n\n"}
			</>
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row"
		}
	},
	"icon5": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--primary",
			"margin": "2px 16px 0px 0px"
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"children": <Strong>
				{"\n"}Оптимізація авто{"\n\n"}
			</Strong>
		}
	},
	"text14": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "12px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"lg-margin": "8px 0px 0px 0px",
			"children": <>
				Ми можемо допомогти вам впровадити різні покращення та модернізації, щоб ваш автомобіль став ще зручнішим та продуктивнішим.{"\n\n"}
			</>
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row"
		}
	},
	"icon6": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--primary",
			"margin": "2px 16px 0px 0px"
		}
	},
	"box15": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text15": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"children": <Strong>
				{"\n"}Зручність і комфорт{"\n\n"}
			</Strong>
		}
	},
	"text16": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "12px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"lg-margin": "8px 0px 0px 0px",
			"children": <>
				Наш сервісний центр надає вам зручність та комфорт під час обслуговування автомобіля. Ми робимо все можливе, щоб ваш візит був приємним і продуктивним.{"\n\n"}
			</>
		}
	},
	"box16": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row"
		}
	},
	"icon7": {
		"kind": "Icon",
		"props": {
			"display": "block",
			"category": "md",
			"icon": MdDone,
			"size": "20px",
			"color": "--primary",
			"margin": "2px 16px 0px 0px"
		}
	},
	"box17": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text17": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"children": <Strong>
				{"\n"}Економія часу і грошей{"\n\n"}
			</Strong>
		}
	},
	"text18": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "12px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "100%",
			"md-width": "100%",
			"text-align": "left",
			"lg-margin": "8px 0px 0px 0px",
			"children": <>
				Ви можете отримати весь комплекс послуг для вашого автомобіля в одному місці, що дозволяє заощадити час і гроші на пошуки і обслуговування в різних місцях.{"\n\n"}
			</>
		}
	}
};

const Advantages = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			grid-gap="5%"
			sm-min-width="280px"
			lg-flex-wrap="wrap"
			display="flex"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Text {...override("text2")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Icon {...override("icon")} />
				<Box {...override("box3")}>
					<Text {...override("text3")} />
					<Text {...override("text4")} />
				</Box>
			</Box>
			<Box {...override("box4")}>
				<Icon {...override("icon1")} />
				<Box {...override("box5")}>
					<Text {...override("text5")} />
					<Text {...override("text6")} />
				</Box>
			</Box>
			<Box {...override("box6")}>
				<Icon {...override("icon2")} />
				<Box {...override("box7")}>
					<Text {...override("text7")} />
					<Text {...override("text8")} />
				</Box>
			</Box>
			<Box {...override("box8")}>
				<Icon {...override("icon3")} />
				<Box {...override("box9")}>
					<Text {...override("text9")} />
					<Text {...override("text10")} />
				</Box>
			</Box>
			<Box {...override("box10")}>
				<Icon {...override("icon4")} />
				<Box {...override("box11")}>
					<Text {...override("text11")} />
					<Text {...override("text12")} />
				</Box>
			</Box>
			<Box {...override("box12")}>
				<Icon {...override("icon5")} />
				<Box {...override("box13")}>
					<Text {...override("text13")} />
					<Text {...override("text14")} />
				</Box>
			</Box>
			<Box {...override("box14")}>
				<Icon {...override("icon6")} />
				<Box {...override("box15")}>
					<Text {...override("text15")} />
					<Text {...override("text16")} />
				</Box>
			</Box>
			<Box {...override("box16")}>
				<Icon {...override("icon7")} />
				<Box {...override("box17")}>
					<Text {...override("text17")} />
					<Text {...override("text18")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Advantages, { ...Section,
	defaultProps,
	overrides
});
export default Advantages;